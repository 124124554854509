/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.App {
	/* font-family: 'Playfair Display', serif; */
	/* font-family: 'roboto'; */
	width: 100vw;
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}

p,
span,
button,
.accordion-item .accordion-header .accordion-button {
	font-family: "Nunito Sans", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}

body {
	overflow-x: hidden;
}

hr {
	color: #5FECC8;
}


@media (max-width: 1170px) {
	.App {
		width: 100vw;
		overflow-x: hidden;
	}
}

/* social media sticky icons */
.sticky-icon {
	z-index: 1;
	position: fixed;
	top: 45%;
	right: 0%;
	width: 40px;
	display: flex;
	flex-direction: column;
}

.sticky-icon a {
	/* transform:translate(185px,0px); */
	border-radius: 50px 0px 0px 50px;
	text-align: left;
	margin: 2px;
	text-decoration: none;
	text-transform: uppercase;
	padding: 3px;
	font-size: 16px;
	font-family: 'Oswald', sans-serif;
	transition: all 0.8s;
}

.sticky-icon a:hover {
	color: #FFF;
	transform: translate(0px, 0px);
}

.sticky-icon a:hover i {
	transform: rotate(360deg);
}

/*.search_icon a:hover i  {
	transform:rotate(360deg);}*/
.Facebook {
	background-color: #2C80D3;
	color: #FFF;
}

.Youtube {
	background-color: #fa0910;
	color: #FFF;
}

.Twitter {
	background-color: #53c5ff;
	color: #FFF;
}

.Instagram {
	background: linear-gradient(to right, #f32170, #cf23cf);
	color: #FFF;
}
.chatbot  {
	background:linear-gradient(to right, #f32170,#cf23cf);;
	color:#FFF;}


.linkedin1 {
	background-color: #0077B5;
	color: #FFF;
}

.sticky-icon a i {
	background-color: #FFF;
	height: 30px;
	width: 30px;
	color: #0077B5;
	text-align: center;
	line-height: 30px;
	border-radius: 50%;
	margin-right: 20px;
	transition: all 0.5s;
	z-index: 100;
}

.sticky-icon a i.fa-facebook-f {
	background-color: #FFF;
	color: #2C80D3;
}

.sticky-icon a i.fa-google-plus-g {
	background-color: #FFF;
	color: #d34836;
}

.sticky-icon a i.fa-instagram {
	background-color: #FFF;
	color: #FD1D1D;
}

.sticky-icon a i.fa-youtube {
	background-color: #FFF;
	color: #fa0910;
}

.sticky-icon a i.fa-twitter {
	background-color: #FFF;
	color: #53c5ff;
}

.fas fa-shopping-cart {
	background-color: #FFF;
}

#myBtn {
	height: 50px;
	display: none;
	position: fixed;
	bottom: 20px;
	right: 30px;
	z-index: 99;
	text-align: center;
	/* padding:10px; */
	text-align: center;
	line-height: 40px;
	border: none;
	outline: none;
	background-color: #1e88e5;
	color: white;
	cursor: pointer;
	border-radius: 50%;
}

.fa-arrow-circle-up {
	font-size: 10px;
}

#myBtn:hover {
	background-color: #555;
}

body::-webkit-scrollbar {
	width: 0em;
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

}

body::-webkit-scrollbar-thumb {
	background: radial-gradient(136.22% 90.82% at 50% 104.6%, #00707E 0%, #005967 20.57%, #003B4A 52.79%, #002937 80.41%, #002231 100%);
	outline: 1px solid radial-gradient(76.18% 60.56% at 50% 50%, #206476 0%, #00091C 100%);
	border-radius: 4px !important;

}

.navbar-toggler {
	background-color: #fff !important;
}

/* top scroll btn */
#button {
	display: inline-block;
	background-color: #5FEEC8;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 4px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	transition: background-color .3s,
		opacity .5s, visibility .5s;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
	text-decoration: none;
}

#button::after {
	content: "\f077";
	font-family: FontAwesome;
	font-weight: normal;
	font-style: normal;
	font-size: 1.5em;
	/* line-height: 50px; */
	color: #000;
}

#button:hover {
	cursor: pointer;
	background-color: #14C3C0;
}

#button:active {
	background-color: #14C3C0;
}

#button.show {
	opacity: 1;
	visibility: visible;
}

@media (min-width: 500px) {
	#button {
		margin: 30px;
	}
}