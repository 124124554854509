.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust this if necessary */
    background: linear-gradient(
      to bottom,
      #00707E 0%,
      #005967 25%,
      #003B4A 50%,
      #002937 75%,
      #002231 100%
    ); 
  }
  
  .loading-container img {
    max-width: 5rem;
    max-height:5rem;
  }